<template>
  <div>
    <!-- 13个科目的ID、科目名称、题目数、试卷数、可用题目数、可用试卷数、最近3年题目数、最近3年试卷数 -->
    <Table ref="TablesRef"
           :tableData="tableData" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [],
      pageName: 'topic_number_admin',
      tableColumns: [
        { prop: 'subject_id', align: 'center', label: '科目ID', width: '', showToopic: false },
        { prop: 'subject_name', align: 'center', label: '科目名称', width: '', showToopic: false },
        { prop: 'question_count', align: 'center', label: '题目数', width: '', showToopic: false },
        { prop: 'paper_count', align: 'center', label: '试卷数', width: '', showToopic: false },
        { prop: 'question_student_count', align: 'center', label: '可用题目数', width: '', showToopic: false },
        { prop: 'paper_student_count', align: 'center', label: '可用试卷数', width: '', showToopic: false },
        { prop: 'question_student_3_count', align: 'center', label: '最近3年题目数', width: '', showToopic: false },
        { prop: 'paper_student_3_count', align: 'center', label: '最近3年试卷数', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'img', btns: [
            { content: '详细情况', type: 'look', event: 'showForm' },
          ]
        },
      ]
    }
  },
  mounted () {
    this.initData()
    this.setTablesColums()
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData () {
      this.$http({
        url: '/api/v2/stats/question',
        method: 'get'
      }).then(res => {
        this.tableData = res.data
      })
    },
    exportExcel () {

    },
    showForm (row) {
      console.log(row)
      this.$router.push('/topicbumber/topicbumber_info?id=' + row.subject_id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>